<template>
  <div>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" elevation="0"
      v-if="transaction && transactionItems">
      <v-list>
        <template v-for="(i, index) in transactionItems">
          <v-list-item :key="'item'+index">
            <v-list-item-avatar>
              <v-avatar size="50" color="grey lighten-4">
                <img v-if="i.product && i.product.image" :src="userImg(i.product.image)" />
                <img v-else-if="academy.image" :src="userImg(academy.image)" />
                <img v-else :src="assets('logo-circular-watermarked.png')" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title two-line v-if="i.product">
                {{i.product.name}}
                <span class="ml-1 gray--text">(x{{i.quantity}})</span>
              </v-list-item-title>
              <v-list-item-title two-line v-else>
                {{i.name}}
                <span class="ml-2">(x{{i.quantity}})</span>
              </v-list-item-title>
              <div v-if="i.product && i.product.interval">
                <v-list-item-subtitle>
                  <v-icon size="15" class="mr-1">mdi-autorenew</v-icon>
                  {{ displayInterval(i.product.interval) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="transaction.subscriptionStartDate" class="primary--text">
                  <span class="mr-1">{{ $t('message.starts') }}</span>
                  {{ $d(new Date(transaction.subscriptionStartDate), 'month_day_year', $i18n.locale) }}
                </v-list-item-subtitle>
              </div>
            </v-list-item-content>
            <v-list-item-action v-if="isNaN(i.amountDecimal) && i.product">
              <span class="title-amount">{{ currencySymbol + i.product.amountDecimal }}</span>
            </v-list-item-action>
            <v-list-item-action v-else>
              <span v-if="i.amountDecimal > 0" class="title-amount">{{ currencySymbol + i.amountDecimal }}</span>
              <v-chip small v-else color="primary">{{ $t('message.free') }}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < transaction.items.length" :key="index"></v-divider>
        </template>
        <div class="text-right mr-2">
          TOTAL: {{transaction.managedAcademy ? transaction.managedAcademy.currencySymbol : '$'}}
          {{ totalAmount }}
        </div>
      </v-list>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" v-if="academy && transaction">
      <v-alert v-if="payingLateMessage" color="gold" type="warning">{{payingLateMessage}}</v-alert>
      <v-list>
        <v-list-item two-line @click="goToAcademy(academy._id)">
          <v-list-item-content>
            <v-list-item-title>{{academy.name}}</v-list-item-title>
            <v-list-item-subtitle>{{$t('message.vendor')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line v-if="transaction.buyer._id != $store.state.user._id">
          <v-list-item-content>
            <v-list-item-title>{{transaction.buyer.displayName}}</v-list-item-title>
            <v-list-item-subtitle>{{$t('message.family_member')}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar>
            <UserAvatar :user="transaction.buyer"></UserAvatar>
          </v-list-item-avatar>
        </v-list-item>
        <v-divider v-if="transaction.buyer._id != $store.state.user._id"></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $d(new Date(transaction.time), 'long', $i18n.locale) }}</v-list-item-title>
            <v-list-item-subtitle>{{$t('message.date')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="transaction.subscriptionStartDate"></v-divider>
        <v-list-item two-line v-if="transaction.subscriptionStartDate">
          <v-list-item-content>
            <v-list-item-title>{{ $d(new Date(transaction.subscriptionStartDate), 'month_day_year', $i18n.locale) }}
            </v-list-item-title>
            <v-list-item-subtitle>{{$t('message.starts_on')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line v-if="transaction.user">
          <v-list-item-content>
            <v-list-item-title>{{transaction.user.fName}} {{transaction.user.lName}}</v-list-item-title>
            <v-list-item-subtitle>{{$t('message.processed_by')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!transaction.items">
          <v-list-item-content>
            <v-list-item-title>{{transaction.quantity}}</v-list-item-title>
            <v-list-item-subtitle>{{$t('message.quantity')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="transaction.prorated && transaction.prorated.amount"></v-divider>
        <v-list-item two-line v-if="transaction.prorated && transaction.prorated.amount">
          <v-list-item-content>
            <v-list-item-title>{{ currencySymbol }}{{ transaction.prorated.amount }}
              <span class="ml-1">- {{ transaction.prorated.days }} {{$t('message.days')}}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{$t('message.prorated_amount')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <span v-html="getPaymentStatus(transaction, subscriptionStatus)"></span>
              <span class="ml-1">
                <DesktopComputerIcon class="hero-icon" :color="getStatusColor(transaction, subscriptionStatus)"
                  v-if="transaction.processedBy == 'desk'" size="20"></DesktopComputerIcon>
                <DeviceMobileIcon class="hero-icon" :color="getStatusColor(transaction, subscriptionStatus)" v-else
                  size="20"></DeviceMobileIcon>
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>{{$t('message.status')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line v-if="transaction.subscriptionCheckins > 0 || transaction.subscriptionEndDate">
          <v-list-item-content>
            <v-list-item-title :class="transaction.expired ? 'red--text' : ''">
              <div v-if="transaction.subscriptionCheckins > 0">
                <span>
                  {{$t('message.allowed')}} {{transaction.subscriptionCheckins}} checkins
                </span>
                <span class="ml-1 gray--color"> ({{$t('message.checkins_left',[getCheckinsLeft()])}})</span>
              </div>
              <div v-if="transaction.subscriptionEndDate">
                {{ $d(new Date(transaction.subscriptionEndDate), 'long', $i18n.locale) }}
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{$t('message.expires')}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line v-if="transaction.coupon">
          <v-list-item-content>
            <v-list-item-title v-if="transaction.coupon.discountPercent">
              {{transaction.coupon.discountPercent}}%
            </v-list-item-title>
            <v-list-item-title v-else-if="transaction.coupon.discountAmount">
              {{ currencySymbol }}{{ transaction.coupon.discountAmount }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <TagIcon class="hero-icon" size="15" :color="themeColor('primary')"></TagIcon>
              {{$t('message.discount')}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="transaction.coupon && transaction.coupon.discountedTotal > 0">
                {{currencySymbol}}
                {{transaction.coupon.discountedTotal}}
              </span>
              <span v-else>
                {{ transaction.managedAcademy.currencySymbol}}{{ totalAmount }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              Total
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption" v-if="taxData && taxData.enabled">
              <span class="mr-1">{{ $t("message.tax") }}:</span>
              <span>{{currencySymbol}}{{centsToAmount(taxData.totalDetails.amount_tax)}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
    <v-sheet v-if="paymentMethod && !transaction.cancelled" :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-2">
      <v-subheader>
        <CreditCardIcon class="hero-icon"></CreditCardIcon>
        {{ $t('message.payment_method') }}
        <v-spacer></v-spacer>
        <v-btn @click="updateCreditCard()" elevation="0" small color="primary">{{ $t('message.update') }}</v-btn>
      </v-subheader>
      <PaymentMethod :height="60" :payment-method="paymentMethod" :hide-name="true"></PaymentMethod>
    </v-sheet>
    <v-sheet v-if="transaction && !transaction.completed && !transaction.cancelled"
      :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-2">
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="session && session.status == 'active'">
          <v-btn :disabled="true" elevation="0" @click="completeCheckout()" small color="primary">
            <CreditCardIcon class="hero-icon"></CreditCardIcon>{{ $t('message.already_completed') }}
          </v-btn>
        </div>
        <v-btn v-else
          :disabled="allowsBankPayment && transaction.paymentTypes.length == 1 && $route.query.inApp ? true : false"
          elevation="0" @click="completeCheckout()" small color="primary">
          <CreditCardIcon class="hero-icon"></CreditCardIcon>{{ $t('message.complete_payment') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <div v-if="allowsBankPayment">
        <v-alert color="primary" outlined type="warning">{{ $t('message.bank_payment_requirement') }}</v-alert>
      </div>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="invoices" class="mt-2">
      <v-card-text>
        <v-list>
          <v-subheader>
            <ReceiptTaxIcon class="hero-icon"></ReceiptTaxIcon>
            {{ $t('message.invoices') }}
          </v-subheader>
          <template v-for="(i, index) in invoices">
            <v-list-item :key="'invoice'+index">
              <v-list-item-action>
                <CreditCardIcon :color="i.paid ? 'green' : 'red'" size="25">card_membership</CreditCardIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $d(unixString2Datetime(i.created), 'long', $i18n.locale) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ currencySymbol }}{{i.amountPaid}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>{{$t('message.attempts')}}: {{i.attempts}}</v-list-item-content>
              <v-list-item-action class="text-left">
                <v-btn small icon v-if="i.refundedAmount != i.amountPaid"
                  @click="sendEmailDialog = true; selectedInvoice = i.paymentIntent;">
                  <v-icon>mdi-receipt-send-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < invoices.length" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2"
      v-if="transaction && transaction.checkins && transaction.checkins.length > 0">
      <div>
        <v-subheader>
          <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
          {{ $t('message.checkins') }}
        </v-subheader>
        <template v-for="(i, index) in transaction.checkins">
          <v-list-item :key="'checkin'+index">
            <v-list-item-avatar>
              <LocationMarkerIcon class="hero-icon" size="25"></LocationMarkerIcon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span>{{i.name}}</span>
              </v-list-item-title>
              <v-list-item-subtitle v-if="i.date">{{ $d(new Date(i.date), 'long', $i18n.locale) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index + 1 < transaction.checkins.length" :key="index"></v-divider>
        </template>
      </div>
    </v-sheet>
    <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="transaction">
      <v-card-actions>
        <v-btn v-if="transaction.completed && (!invoices || invoices.length == 0)" small elevation="0"
          @click="sendEmailDialog = true;" color="primary">
          {{ $t("message.email_receipt") }}
        </v-btn>
        <v-btn v-if="transaction.completed" color="error" small elevation="0" @click="requestCancellation()">
          {{ $t('message.request_cancellation') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small elevation="0" @click="navigateTo({ name: 'myacademytab', params:{tabId:2}})">
          {{$t('message.back')}}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-dialog v-model="sendEmailDialog" width="90%">
      <v-card outlined>
        <v-card-title>
          <h2>{{ $t("message.email_receipt") }}</h2>
        </v-card-title>
        <v-card-title>
          <v-switch v-model="altEmailConfirm" :label="$t('message.use_different_email')"></v-switch>
        </v-card-title>
        <v-card-title>
          <v-text-field :disabled="!altEmailConfirm" single-line outline :label="$t('message.email')"
            v-model="altEmail"></v-text-field>
        </v-card-title>
        <v-card-actions>
          <v-btn elevation="0" small :disabled="false" color="primary" @click="emailReceipt()">
            {{
            $t("message.email")
            }}
          </v-btn>
          <v-btn elevation="0" small color="secondary" @click="sendEmailDialog = false; selectedInvoice=null;">
            {{
            $t("message.close")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentsService from "@/services/PaymentsService";
import PaymentMethod from "@/components/displays/PaymentMethodDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay.vue";

//Icons
import {
  DesktopComputerIcon,
  ReceiptTaxIcon,
  TagIcon,
  CreditCardIcon,
  LocationMarkerIcon,
  DeviceMobileIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      transactionId: this.$route.params.transactionId,
      queryError: this.$route.query.error,
      sessionId: this.$route.query.sessionId,
      sessionStatus: this.$route.query.status,
      checkoutType: this.$route.query.type,
      session: null,
      transaction: null,
      currencySymbol: "$",
      academy: null,
      loading: true,
      allowsBankPayment: false,
      product: null,
      payingLateMessage: null,
      subscriptionStatus: null,
      checkins: 0,
      amount: 0,
      paymentMethod: null,
      altEmail: null,
      selectedInvoice: null,
      sendEmailDialog: null,
      transactionItems: [],
      totalAmount: 0,
      altEmailConfirm: null,
      taxData: { enabled: false },
      invoices: null,
      intervals: [
        { text: this.$t("message.daily"), value: "day" },
        { text: this.$t("message.weekly"), value: "week" },
        { text: this.$t("message.monthly"), value: "month" },
        { text: this.$t("message.yearly"), value: "year" }
      ]
    };
  },
  components: {
    DesktopComputerIcon,
    ReceiptTaxIcon,
    TagIcon,
    CreditCardIcon,
    PaymentMethod,
    LocationMarkerIcon,
    DeviceMobileIcon,
    UserAvatar
  },
  created() {
    //Get transaction, if not cancelled, proceed
    if (this.transactionId) {
      if (this.sessionId && this.sessionStatus == "success") {
        if (this.checkoutType && this.checkoutType == 'update')
          this.completeCreditCardUpdate();
      } else {
        this.loadTransaction();
        if (this.queryError)
          this.showAlert("error", this.$t("message.incomplete"));
      }
    }
  },
  methods: {
    requestCancellation() {
      PaymentsService.requestCancellation(this.transactionId)
        .then(response => {
          if (response)
            this.showAlert("success", this.$t("message.email_sent_to_academy"));
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    completePurchase() {
      const id = this.session.id;
      if (!id) {
        this.showAlert("error", this.$t("message.error_generic"));
        return;
      }
      PaymentsService.complete(id)
        .then(response => {
          if (response) {
            const vm = this;
            setTimeout(function () {
              vm.loadTransaction();
            }, 1000);
          }
        })
        .catch(() => {
          this.getTransactions();
        });
    },
    loadTransaction() {
      PaymentsService.transaction(this.transactionId)
        .then(response => {
          this.transaction = response.transaction;
          if (this.transaction.product) {
            //WE now display the product or products always as a list
            this.transactionItems = [
              {
                product: this.transaction.product,
                amountDecimal: this.transaction.product.amountDecimal,
                quantity: 1
              }
            ];
          }
          console.log('this.transaction.recurrence:', this.transaction.recurrence)
          if (this.transaction.recurrence) {
            this.totalAmount = this.transaction.recurrence.totalAmount;
          } else {
            this.totalAmount = this.transaction.amount * this.transaction.quantity
          }
          if (this.transaction.items && this.transaction.items.length) {
            for (let i of this.transaction.items) {
              this.transactionItems.push({
                product: i.product,
                amountDecimal: i.amountDecimal,
                name: i.name,
                description: i.description,
                quantity: 1
              });
            }
          }
          this.session = response.session ? response.session : { status: 'inactive' };
          if (this.session.status == 'active' && !this.transaction.completed) {
            this.completePurchase();
          }
          if (this.transaction.managedAcademy && this.transaction.managedAcademy.currencySymbol)
            this.currencySymbol = this.transaction.managedAcademy.currencySymbol;
          if (response.automaticTax) {
            this.taxData = {
              enabled: true,
              totalDetails: response.totalDetails
            };
          }
          this.academy = response.academy;
          this.checkins = response.checkins;
          this.product = this.transaction.product;
          this.subscriptionStatus = response.status;
          if (!this.transaction.completed && !this.transaction.cancelled && this.product && this.product.type == "service") {
            const d2 = this.toEndOfDay(new Date());
            const d1 = new Date(this.transaction.time);
            const daysBetween = Math.abs(this.daysBetween(d1, d2));
            if (daysBetween > 1) {
              const dateDisplay = this.$d(new Date(this.transaction.time), 'month_day', this.$i18n.locale)
              this.payingLateMessage = this.$t("message.pay_subcription_late_explained", [daysBetween, dateDisplay]);
            }
          }
          this.invoices = response.invoices;
          if (this.checkoutType && this.checkoutType == 'update') {
            const invoicesCount = this.invoices ? this.invoices.length : 0;
            let latestInvoice = null;
            //latestInvoice.amountPaid == 0 && latestInvoice.attempts >= 1
            if (invoicesCount > 0 && this.invoices[invoicesCount - 1])
              latestInvoice = this.invoices[invoicesCount - 1];
            if (latestInvoice && latestInvoice.amountPaid == 0 && latestInvoice.attempts >= 1) {
              PaymentsService.retryCardPayment(this.transactionId, this.invoices[invoicesCount - 1].paymentIntent);
            }
          }
          this.amount = this.transaction.amount ? this.transaction.amount : 0;
          this.paymentMethod = response.paymentMethod;
          this.loading = false;
          const paymentTypes = this.transaction.paymentTypes;
          if (paymentTypes && paymentTypes.length > 0) {
            for (const pt of paymentTypes) {
              if (pt.indexOf('bank') >= 0) {
                this.allowsBankPayment = true;
                return;
              }
            }
          }
        })
        .catch(e => {
          console.log(e)
          if (e)
            this.showAlert("error", this.$t("message.error_generic"));
          this.loading = false;
        });
    },
    completeCheckout() {
      if (this.transaction.paymentsModule == 'infinicept') {
        this.navigateTo({
          name: "inifnicept-checkout",
          params: {
            transactionId: this.transactionId,
            managedAcademyId: this.managedAcademy,
          }
        });
      } else {
        this.navigateTo({
          name: "checkout",
          params: { transactionId: this.transactionId },
        });
      }
    },
    completeCreditCardUpdate() {
      PaymentsService.completeCreditCardUpdate(this.transactionId, this.sessionId)
        .then(response => {
          if (response) {
            this.showAlert("success", this.$t("message.updated"));
            this.loadTransaction();
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.error_generic"));
          this.loading = false;
          this.loadTransaction();
        });
    },
    updateCreditCard() {
      PaymentsService.initUpdateCreditCardSession(this.transactionId)
        .then(response => {
          const session = response.session;
          this.navigateTo({
            name: "ccupdate",
            params: { transactionId: this.transactionId },
            query: { s: session.id }
          });
        })
        .catch(e => {
          if (e) {
            if (e.response.data && e.response.data.message)
              this.showAlert("error", e.response.data.message);
            else
              this.showAlert("error", this.$t("message.error_generic"));
          }
        });
    },
    getCheckinsLeft() {
      let num = 0;
      if (this.transaction.subscriptionCheckins) {
        let checkins = this.transaction.checkins ? this.transaction.checkins.length : 0;
        num = this.transaction.subscriptionCheckins - checkins;
        if (num < 0)
          num = 0;
      }
      return num;
    },
    emailReceipt() {
      PaymentsService.emailReceipt(this.transactionId, this.altEmail, this.selectedInvoice)
        .then(() => {
          this.sendEmailDialog = false;
          this.selectedInvoice = null;
          this.showAlert("info", this.$t("message.success"));
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    goToAcademy(id) {
      this.navigateTo({ name: "viewacademy", params: { academyId: id } });
    },
    displayInterval() {
      for (const i of this.intervals) {
        if (i.value == this.product.interval) return i.text;
      }
    },
  }
};
</script>

<style scoped>
.expired {
  color: #EC7063;
  font-size: medium;
}
</style>